import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81')
];

export const server_loads = [0,4,2];

export const dictionary = {
		"/(orion)": [~29,[4]],
		"/404": [77],
		"/(auth)/admin": [13,[2]],
		"/(backoffice)/backoffice": [23,[3]],
		"/(backoffice)/backoffice/bandeiras": [24,[3]],
		"/(backoffice)/backoffice/bandeiras/[id]": [25,[3]],
		"/(backoffice)/backoffice/disparadores": [26,[3]],
		"/(backoffice)/backoffice/marretagens": [27,[3]],
		"/(backoffice)/backoffice/vendas": [28,[3]],
		"/(orion)/banners": [30,[4,5]],
		"/(orion)/banners/cadastrar": [32,[4,5]],
		"/(orion)/banners/[id]": [31,[4,5]],
		"/(auth)/cadastrar": [14,[2]],
		"/(orion)/cadastro-promocao": [~33,[4,6]],
		"/(orion)/categorias": [34,[4,7]],
		"/(orion)/categorias/cadastrar": [36,[4,7]],
		"/(orion)/categorias/[id]": [35,[4,7]],
		"/(orion)/cursos": [37,[4,8]],
		"/(orion)/cursos/gerenciar/(listar)": [40,[4,8]],
		"/(orion)/cursos/gerenciar/cadastrar": [42,[4,8]],
		"/(orion)/cursos/gerenciar/[id]": [41,[4,8]],
		"/(orion)/cursos/pesquisar": [43,[4,8]],
		"/(orion)/cursos/solucoes": [~44,[4,8]],
		"/(orion)/cursos/solucoes/[id]": [~45,[4,8]],
		"/(orion)/cursos/solucoes/[id]/[treinamento]": [46,[4,8]],
		"/(orion)/cursos/[id]": [38,[4,8]],
		"/(orion)/cursos/[id]/[aula]": [39,[4,8]],
		"/erro": [~79],
		"/(orion)/fidelizamais": [47,[4]],
		"/(orion)/fidelizamais/criar": [48,[4]],
		"/(orion)/fidelizamais/gerenciar": [49,[4]],
		"/icons": [80],
		"/(auth)/login": [15,[2]],
		"/(auth)/logout": [~16,[2]],
		"/(orion)/mais": [50,[4]],
		"/(orion)/mais/noticias": [51,[4]],
		"/(orion)/mais/noticias/lancamentos": [53,[4]],
		"/(orion)/mais/noticias/lancamentos/[id]": [54,[4]],
		"/(orion)/mais/noticias/[id]": [52,[4]],
		"/(orion)/mais/perfil": [55,[4]],
		"/(orion)/medicamentos": [56,[4,9]],
		"/(orion)/medicamentos/pbm": [57,[4,9]],
		"/(orion)/medicamentos/pbm/gerenciar": [58,[4,9]],
		"/(orion)/medicamentos/pbm/orientacoes": [59,[4,9,10]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar": [61,[4,9,10,11]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/cadastrar": [63,[4,9,10,11]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/[id]": [62,[4,9,10,11]],
		"/(orion)/medicamentos/pbm/orientacoes/[id]": [60,[4,9,10]],
		"/(orion)/noticias": [64,[4]],
		"/(orion)/noticias/gerenciar": [66,[4,12]],
		"/(orion)/noticias/gerenciar/cadastrar": [68,[4,12]],
		"/(orion)/noticias/gerenciar/[id]": [67,[4,12]],
		"/(orion)/noticias/lancamentos": [69,[4]],
		"/(orion)/noticias/lancamentos/[id]": [70,[4]],
		"/(orion)/noticias/[id]": [65,[4]],
		"/(orion)/ofertas": [71,[4]],
		"/(orion)/perfil": [72,[4]],
		"/privacidade": [81],
		"/(orion)/promocoes": [~73,[4]],
		"/(orion)/promocoes/acelerador-resgatado": [74,[4]],
		"/(auth)/recuperar-senha": [17,[2]],
		"/(auth)/redefinir-senha": [18,[2]],
		"/(auth)/sso": [~19,[2]],
		"/(auth)/sso/[token]": [20,[2]],
		"/(orion)/trade": [75,[4]],
		"/(orion)/troca-email": [76,[4]],
		"/(auth)/v2sso": [~21,[2]],
		"/(auth)/v2sso/[token]": [~22,[2]],
		"/[...naoencontrada]": [~78]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';